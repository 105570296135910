import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import MainSkills from '../components/MainSkills'
import TechnicalSkills from '../components/TechnicalSkills'
import Experience from '../components/Experience'

class HomeIndex extends React.Component {
  render() {
    const siteTitle = 'Techdesigner.co.uk'
    const siteDescription = 'Site description'

    return (
      <Layout>
        <Helmet>
          <title>{siteTitle}</title>
          <meta name="description" content={siteDescription} />
        </Helmet>

        <div id="main">
          <section id="one">
            <header className="major">
              <h2>About me</h2>
            </header>
            <p>
              I am a well-organized and highly skilled software engineer who has
              the capability to work with confidence both independently and
              within a team environment.
            </p>

            <p>
              I have a deep understanding of object oriented development and
              design. I possess the professionalism and skills to quickly adapt
              to new technologies and environments. Over 18 years of software
              engineering experience have provided the capability to solve
              complex problems and produce innovative solutions.
            </p>

            <p>
              In addition to a passion for design and implementation, mentoring
              and the teaching of others has become a rewarding and valuable
              element of my skill set. I am a good team player, have a strong
              belief in knowledge sharing and have the ability to utilise and
              enforce software development quality processes. I am flexible,
              open to challenges and will enthusiastically embark any project. I
              am currently looking for contracting positions in London and
              Milton Keynes.
            </p>
          </section>

          <section id="two">
            <header className="major">
              <h2>Main skills</h2>
            </header>
            <MainSkills />
          </section>

          <section id="three">
            <header className="major">
              <h2>Technical skills</h2>
            </header>
            <TechnicalSkills />
          </section>

          <Experience id="four" />

          <section id="five">
            <h2>Get in touch</h2>
            <p>
              I am looking for contracting opportunities in London and Milton
              Keynes.
            </p>
            <div className="row">
              <div className="8u 12u$(small)">
                <form
                  name="contact"
                  method="post"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact" />
                  <div className="row uniform 50%">
                    <div className="6u 12u$(xsmall)">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Name"
                      />
                    </div>
                    <div className="6u 12u$(xsmall)">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email"
                      />
                    </div>
                    <div className="12u">
                      <textarea
                        name="message"
                        id="message"
                        placeholder="Message"
                        rows="4"
                      />
                    </div>
                  </div>
                </form>
                <ul className="actions">
                  <li>
                    <input
                      type="submit"
                      value="Send Message"
                      className="special"
                    />
                  </li>
                </ul>
              </div>
              <div className="4u 12u$(small)">
                <ul className="labeled-icons">
                  <li>
                    <h3 className="icon fa-envelope-o">
                      <span className="label">Email</span>
                    </h3>
                    <a href="mailto:gunesserkan@gmail.com">
                      gunesserkan@gmail.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default HomeIndex
