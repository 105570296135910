import React from 'react'
import { useInView } from 'react-intersection-observer'
import { ProgressBar } from 'react-step-progress-bar'

const SKILLS = [
  {
    type: 'Software Design',
    level: 100,
  },
  {
    type: 'Platform Design',
    level: 95,
  },
  {
    type: 'Micro services',
    level: 85,
  },
  {
    type: 'Frontend Development',
    level: 100,
  },
  {
    type: 'Backend Development',
    level: 100,
  },
  {
    type: 'Mobile Development',
    level: 70,
  },
  {
    type: 'Technical Team Leading',
    level: 100,
  },
]

export default () => {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 1,
    triggerOnce: true,
  })

  return SKILLS.map(skill => [
    <span ref={ref}>{skill.type}</span>,
    <ProgressBar
      percent={inView ? skill.level : 0}
      filledBackground="rgba(0, 116, 217, 0.8)"
      height={22}
      text={skill.level + '%'}
    />,
  ])
}
