import React from 'react'
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBriefcase, faStar } from '@fortawesome/free-solid-svg-icons'

const WorkIcon = <FontAwesomeIcon icon={faBriefcase}/>
const StarIcon = <FontAwesomeIcon icon={faStar}/>

const positions = [
    {
    title: 'CTO - Co-founder',
    company: 'CardAlpha',
    date: '08-2018 - Present',
    description: [
      'Co-founded and created CardAlpha. Electronic point of sale system',
      'Designed and developed CardAlpha\'s micro-services backend which is running on Google Cloud using docker and kubernetes. Backend is written entirely in Golang',
      'Designed and developed CardAlpha\'s IOS App using Swift and React(TypeScript)'
    ],
  },
  {
    title: 'Senior Systems Architect',
    company: 'CMC Markets',
    date: '04-2020 - Present',
    description: [
      'I design and develop frontend systems for CMC Market client facing and internal projects. Starting from systems design, technology selection, project setup; all the way to production deployment.',
    ],
  },
  {
    title: 'Senior Software Consultant',
    company: 'CMC Markets',
    date: '10-2018 - 04-2020',
    description: [
      'Developed next generation trading applications using HTML5 and Javascript(ES7), React and AngularJS',
      'Developed next generation on-boarding application using React, Redux',
    ],
  },
  {
    title: 'Technical Team Leader',
    company: 'CMC Markets',
    date: '04-2013 - 10-2018',
    description: [
      'Led team of 12 senior developers.',
      'Designed and developed next generation trading applications using HTML5 and Javascript(ES7), React and AngularJS',
    ],
  },
  {
    title: 'Senior Software Engineer',
    company: 'CMC Markets',
    date: '06-2010 - 04-2013',
    description: [
      'Developed Next Generation Trading Applications Using Flex, ActionScript3, RobotLegs, Glue',
    ],
  },
  {
    title: 'Senior Java Developer',
    company: 'CMC Markets',
    date: '01-2007 - 06-2010',
    description: [
      'Backoffice development using Oracle PL/SQL and Java',
    ],
  },
  {
    title: 'Senior Software Engineer/Technical Team Leader',
    company: 'Empower Interactive',
    date: '10-2004 - 12-2006',
    description: [
      'Java Development for Next Generation Telecoms MMS and SMS Gateways',
    ],
  },
  {
    title: 'Software Specialist',
    company: 'Innova IT Solutions',
    date: '2003 - 10-2004',
    description: [
      'Project based software developer. Mostly web application development with Java, J2EE and ASP.Net C# and various database technologies.',
    ],
  },
]

export default (props) => {
  const positionElements = positions.map((position, i) => (
    <VerticalTimelineElement
    key={i}
      className="vertical-timeline-element--work"
      date={position.date}
      iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
      icon={WorkIcon}
    >
      <h3 className="vertical-timeline-element-title">{position.title}</h3>
      <h4 className="vertical-timeline-element-subtitle">{position.company}</h4>
      {position.description.map(line => (<p>{line}</p>))}
    </VerticalTimelineElement>
  ))

  return (
    <section id={props.id}>
      <header className="major">
        <h2>Experience</h2>
      </header>
      <VerticalTimeline>
      {positionElements}
        <VerticalTimelineElement
          iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
          icon={StarIcon}
        />
      </VerticalTimeline>
    </section>
  )
}
